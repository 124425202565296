export const addFloorAreaLayoutVisuals = [
  {
    name: "Demo",
    markdown: `![Add floor area layout GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addFloorAreaLayout = {
  order: 38,
  name: "Add a floor area layout",
  keyword: "plan test-fit buildout build-out",
  subcategory: "Properties",
  markdown: `# Add a floor area layout

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  One of the major drivers of a {% inlineRouterLink articleId="add-a-floor-area" %}floor area's{% /inlineRouterLink %} value is how it is (or can be) built out. Tower Hunt uses the term {% inlineAppIcon iconName="layout" /%}{% inlineRouterLink %}**floor area layout**{% /inlineRouterLink %} to mean the types and numbers of rooms/spaces that can fit in floor areas. {% inlineAppIcon iconName="layout" /%}Layouts are visible in the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.

  {% callout type="tip" %}
  **Tip:** A {% inlineAppIcon iconName="layout" /%}layout can span multiple floor areas. Conversely, a floor area can have multiple {% inlineAppIcon iconName="layout" /%}layouts.
  {% /callout %}

  ## What to expect

  A newly created {% inlineAppIcon iconName="layout" /%}floor area layout contains no additional {% inlineRouterLink %}datapoints{% /inlineRouterLink %}. Layouts are more helpful when some of the following {% inlineAppIcon iconName="private" /%}{% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} datapoints are added:

  - {% inlineRouterLink articleId="add-rooms-to-floor-area-layouts" %}Rooms{% /inlineRouterLink %} illustrate the suggested function of the space. Available room types are influenced by the floor area's {% inlineRouterLink articleId="add-property-uses" %}property uses{% /inlineRouterLink %}.
  - {% inlineRouterLink articleId="add-a-milestone-date" %}Milestone dates{% /inlineRouterLink %} govern the status of the layout and when it exists
  - {% inlineRouterLink articleId="add-files-to-datapoints" %}Files{% /inlineRouterLink %} can be used to show visual aids such as renderings, videos, and photos.
  - {% inlineRouterLink articleId="add-a-name" %}Names{% /inlineRouterLink %} summarize the intent of each layout

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="add-a-floor-area" %}floor area{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="addLayout" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %} that the layout covers.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. The {% inlineAppIcon iconName="layout" /%}layout appears in the lower-left corner of the related floor area(s).
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$addFloorAreaLayoutVisuals /%}
  `,
};
